<template>
  <div>
    <b-form>
      <b-row>
        <!-- Field: Type -->
        <b-col cols="12" md="8">
          <b-form-group label="Product" label-for="user-status">
            <v-select
              v-model="model.productId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryTypeOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
              type="booleano"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="">
            <b-button
              variant="primary"
              class="mt-2"
              @click="addRelationCategoryService()"
            >
              <span class="text-nowrap">Add</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" :to="{ name: 'apps-category-add' }">
                <span class="text-nowrap">Add Category</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status === true ? "Active" : "Inactive" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50" @click="updateStatus(data.item)"
                >Update Status</span
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50" @click="confirmDelete(data.item)"
                >Delete</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./relationCategoryProduct";
import userStoreModule from "../userStoreModule";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,

    BFormGroup,
    BForm,
  },

  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCountry(data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const toast = useToast();

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemCountries,
    } = useUsersList();

    //const statusOptions = ref([])
    const roleOptions = dataItemCountries;
    const statusOptions = dataItemStatus;
    const categoryTypeOptions = ref([]);
    const userData1 = ref(null);

    const { id, name, status, typeId } = props.userData;
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;
    const model = ref({
      productId: "",
      categoryId: router.currentRoute.params.id,
      idRol,
      idOffice,
      status: true,
    });

    const type = [];
    store
      .dispatch("app-user/getHelperSales", { idOffice, idRol })
      .then((response) => {
        const { products } = response.data.data;

        products.map((data, item) => {
          type.push({ label: data.name, value: data.id, id: item });
        });

        categoryTypeOptions.value = type;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      model,
      categoryTypeOptions,

      updateStatus: (data) => {
        console.log(data);
        const { id, status, name } = data;
        const isTrueSet =
          status == String(status ? true : false)
            ? status
              ? true
              : false
            : !status
            ? true
            : false;
        store
          .dispatch("app-user/updateRelationProductsStatus", {
            status: isTrueSet,
            id,
          })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The category ${name} was update status correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            refetchData();
          });
      },
      deleteCountry: (data) => {
        const { id, name } = data;
        data.id = id;
        data.idOffice = idOffice;
        data.idRol = idRol;
        data.idCategory = router.currentRoute.params.id;
        store
          .dispatch("app-user/deleteRelationProductsStatus", data)
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The relation ${name} was delete correctly`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            refetchData();
          });
      },
      addRelationCategoryService: () => {
        store
          .dispatch("app-user/addRelationCategoryProduct", { model })
          .then((response) => {
            userData1.value = response.data;
            toast({
              component: ToastificationContent,
              props: {
                title: `The relation  was created correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            refetchData();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              /*  userData1.value = undefined; */
              toast({
                component: ToastificationContent,
                props: {
                  title: `The relation exist`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
