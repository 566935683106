import { ref, watch, computed } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "product.name", label: "Name", sortable: true },
    { key: "product.price", label: "Price", sortable: true },
    { key: "product.status", label: "Status Product", sortable: true },
    { key: "status", label: "Status Relation", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const statusFilter = ref(null);
  const itemsCountries = ref([]);
  const itemsStatus = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch("app-user/getRelationCategoriesProducts", {
        q: searchQuery.value.toLowerCase(),
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        name: roleFilter.value,
        status: statusFilter.value,
        categoryId: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data, total } = response.data;
        callback(data);
        totalUsers.value = total;
        const arrayCountry = [];
        const arrayData = [];
        const setObj = new Set();
        data.map((data, item) => {
          arrayCountry.push({ label: data.name, value: data.name });
          if (!setObj.has(data.status)) {
            setObj.add(data.status, data);
            arrayData.push({
              label: data.status === true ? "Active" : "Inactive",
              value: data.status,
              id: item,
            });
          }
        });
        itemsCountries.value = arrayCountry;
        itemsStatus.value = arrayData;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching country list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === true) return "success";
    if (status === false) return "warning";
    return "success";
  };

  const dataItemCountries = itemsCountries;
  const dataItemStatus = itemsStatus;

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
    dataItemStatus,
    dataItemCountries,
  };
}
