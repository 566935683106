<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Type -->
        <b-col cols="12" md="4">
          <b-form-group label="Type Category" label-for="user-status">
            <v-select
              v-model="model.typeId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryTypeOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
              type="booleano"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Name Category" label-for="nameCountry">
            <b-form-input id="username" v-model="model.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="model.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
              type="booleano"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateCountry(userData.id)"
    >
      Save Changes Categories
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useUsersList from "../category-list/useUsersList";
import userStoreModule from "../userStoreModule";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    const { resolveUserRoleVariant } = useUsersList();

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    const categoryTypeOptions = ref([]);

    const userData1 = ref(null);

    const { id, name, status, typeId } = props.userData;

    const model = ref({
      id: id,
      name: name,
      status: status,
      typeId: typeId,
    });

    const type = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { categoryType } = response.data.data;
        categoryType.map((data, item) => {
          if (data.id === props.userData.typeId)
            type.push({ label: data.name, value: data.id, id: item });
        });

        categoryTypeOptions.value = type;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const updateCountry = (id) => {
      store
        .dispatch("app-user/updateCountry", { model })
        .then((response) => {
          userData1.value = response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: `The category  was created correctly`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push({ name: "apps-category-list" });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData1.value = undefined;
          }
        });
      console.log(userData1);
    };

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      categoryTypeOptions,
      model,

      updateCountry,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
